import React from "react";
import PT from "prop-types";

export function FaceBookIcon({ color = "#000", height = 24, width = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M9.625 21v-8.05H7v-3.2h2.625V7.219c0-1.336.364-2.373 1.09-3.112C11.444 3.37 12.41 3 13.615 3c.977 0 1.772.047 2.386.14v2.848h-1.636c-.614 0-1.034.14-1.262.422-.182.235-.272.61-.272 1.125V9.75h2.897l-.409 3.2H12.83V21H9.625z"
        />
      </defs>
      <use fill={color} fillRule="evenodd" xlinkHref="#a" />
    </svg>
  );
}

FaceBookIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
