import get from "lodash/get";

// Validate the Email
export function isValidEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!re.test(email)) return false;
  if (email.length > 150 || email.length < 6) return false;
  return true;
}

export function encode(value) {
  return window.btoa(value);
}

export function getCollectionName(collection) {
  return get(collection, ["associated-metadata", "show_collection_name"], false) ? collection.name : "";
}

export function getHeadline(story) {
  const headline = get(story, ["alternative", "home", "default", "headline"], story.headline);
  return headline || story.headline;
}

export function getImageAttributes(story) {
  const slug = get(
    story,
    ["alternative", "home", "default", "hero-image", "hero-image-s3-key"],
    story["hero-image-s3-key"]
  );
  const metadata = get(
    story,
    ["alternative", "home", "default", "hero-image", "hero-image-metadata"],
    story["hero-image-metadata"]
  );

  return {
    slug,
    metadata
  };
}
