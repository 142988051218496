import React from "react";
import PT from "prop-types";
import "./textfield.m.css";

export const TextField = ({ value, type, error, ...props }) => {
  return (
    <div styleName="wrapper" className="wrapper-textfield">
      <input type={type} value={value || ""} styleName="textfield" {...props} className="textfield" />
      {error && <span styleName="error">{error}</span>}
    </div>
  );
};

TextField.defaultProps = {
  type: "email"
};

TextField.propTypes = {
  type: PT.string,
  value: PT.string,
  error: PT.string
};
