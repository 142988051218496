import React from "react";
import PT from "prop-types";
import "./ranking.m.css";
import get from "lodash/get";
import { ResponsiveImage } from "@quintype/components";

export const Ranking = ({ entity }) => {
  const { name, type, metadata } = entity;

  if (type !== "collection") {
    return null;
  }
  const metadataCoverImage = get(metadata, "cover-image", {});

  return (
    <a styleName="wrapper" className="ranking" href={metadata["look-inside-link"]}>
      <figure styleName="qt-image-4x3" className="ranking-image">
        <ResponsiveImage
          slug={metadataCoverImage["cover-image-s3-key"]}
          metadata={metadataCoverImage}
          alt={name}
          aspectRatio={[4, 3]}
          defaultWidth={480}
          widths={[250, 480, 640]}
          sizes="(max-width: 500px) 98vw, (max-width: 768px) 48vw, 23vw"
          imgParams={{ auto: ["format", "compress"] }}
          styleName="image-wrapper"
        />
      </figure>
      {name && (
        <span styleName="title" className="ranking-title">
          {name}
        </span>
      )}
    </a>
  );
};

Ranking.propTypes = {
  entity: PT.object.isRequired
};
