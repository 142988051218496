import React from "react";
import PT from "prop-types";

export function QuintypeIcon({ color = "#000", height = 24, width = 24 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <path
        fill={color}
        fillRule="nonzero"
        d="M24.796 23.155l-1.065 1.326s-1.223-2.308-4.168-1.531c0 0 5.505-3.542 4.962-9.598-.544-6.056-4.962-9.164-5.256-9.347-.295-.182-4.366 4.365-8.58 5.53 0 0 .192-4.296 3.36-4.684v-.549S5.987 5.445 6 15.09c0 0 .313 8.398 8.549 8.752l3.198.137s2.698-.137 3.242 3.68l.408.342L25 23.361l-.204-.206zm-14.397-7.747c-.022-2.353.174-5.313.174-5.313l1.702-.628v10.329l-1.068.343s-.785-2.377-.808-4.73zm7.707 7.816s-1.395.411-3.525.114-3.303-2.8-3.303-2.8l5.054-1.336V6.77l1.774 1.737v14.717zm.38-14.26c5.325 7.359 0 14.123 0 14.123V8.964z"
      />
    </svg>
  );
}

QuintypeIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
