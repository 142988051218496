import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { AuthorImage, AuthorName, Headline } from "../../atoms";
import { StorySummary } from "../../atoms/story-content";
import { getHeadline } from "../../helper/utils";

import "./story-tile-with-author-image.m.css";

export const StoryTileWithAuthorImage = ({ story, fontSize, isHeadlinePresent, headlineCount, summaryLineCount }) => {
  const { authors, subheadline } = story;
  const authorName = authors[0]["name"] || "";
  const slug = isHeadlinePresent ? story.slug : `author/${story.authors[0].id}`;

  return (
    <Link href={`/${slug}`}>
      <div styleName={isHeadlinePresent ? "wrapper-headline" : "wrapper-without-headline"}>
        <AuthorImage authorObj={authors[0]} />
        <div styleName="content">
          <AuthorName name={authorName} fontSize={fontSize} />
          {isHeadlinePresent && <Headline value={getHeadline(story)} fontSize="large" lines={headlineCount} />}
          <StorySummary storySubheadline={subheadline} lines={summaryLineCount} />
        </div>
      </div>
    </Link>
  );
};

StoryTileWithAuthorImage.defaultProps = {
  isHeadlinePresent: true,
  fontSize: "base"
};

StoryTileWithAuthorImage.propTypes = {
  story: PT.object.isRequired,
  isHeadlinePresent: PT.bool,
  fontSize: PT.oneOf(["large", "base"]),
  headlineCount: PT.number,
  summaryLineCount: PT.number
};
