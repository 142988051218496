import React from "react";
import PT from "prop-types";
import Shiitake from "shiitake";
import "./story-summary.m.css";

export const StorySummary = ({ storySubheadline, lines }) => {
  return (
    <Shiitake lines={lines} styleName="summary" className="summary" tagName="div">
      {storySubheadline}
    </Shiitake>
  );
};

StorySummary.defaultProps = {
  lines: 4
};

StorySummary.propTypes = {
  storySubheadline: PT.string,
  lines: PT.number
};
