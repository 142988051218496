import React from "react";
import PT from "prop-types";
import "./gotopicon.m.css";

export const GoTopIcon = ({ color = "#797979", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      styleName="go-to-top-icon"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Fortune-Home-V3" transform="translate(-1325.000000, -3403.000000)" stroke={color}>
          <g id="Group-2" transform="translate(1326.000000, 3404.000000)">
            <circle id="Oval" cx="24" cy="24" r="24" styleName="oval" />
            <polyline id="Path2" points="16 28 24 20 32 28" styleName="oval" />
          </g>
        </g>
      </g>
    </svg>
  );
};

GoTopIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
