import React from "react";
import PT from "prop-types";

import { SearchIcon, CloseIcon } from "../icon";
import "./search.m.css";

function SearchBase(props) {
  return (
    <React.Fragment>
      <div id="searchBox" styleName="qt-search-form" style={props.formStyle} className="qt-search-forms">
        <div styleName="search-mobile">
          <form action="/search" method="get">
            <div styleName="input-group">
              <input id={props.id} styleName="header-search" type="search" placeholder="Search.." name="q" required />
              <span className="input-group-btn">
                <button type="submit" styleName="btn-search" name="sa">
                  <SearchIcon color="#000" width={18} height={18} />
                </button>
              </span>
            </div>
          </form>
        </div>
        <div styleName="close-icon" onClick={props.toggleSearchForm}>
          <CloseIcon color="#fff" />
        </div>
      </div>
      <button styleName="btn_search" onClick={props.toggleSearchForm} style={props.initialStyle}>
        <SearchIcon color="#fff" width={18} height={18} />
      </button>
    </React.Fragment>
  );
}
export { SearchBase };

SearchBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  searchStyle: PT.object,
  id: PT.string,
  toggleSearchForm: PT.func
};
