import React from "react";
import PT from "prop-types";
import "./subheadline.m.css";
import Shiitake from "shiitake";

export const SubHeadline = ({ value, lines }) => {
  return (
    <Shiitake lines={lines} styleName="subheadline" className="subheadline">
      {value.replace("&amp;", "&")}
    </Shiitake>
  );
};

SubHeadline.defaultProps = {
  lines: 4
};

SubHeadline.propTypes = {
  value: PT.string,
  lines: PT.number
};
