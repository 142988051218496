import React from "react";
import PT from "prop-types";
import "./collection-name.m.css";

export const CollectionName = ({ name }) => {
  return (
    <h2 styleName="title" className="collection">
      {name}
    </h2>
  );
};

CollectionName.propTypes = {
  name: PT.string
};
