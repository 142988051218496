import React from "react";
import PT from "prop-types";

export const YoutubeIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.658 7.875c.145.563.249 1.417.31 2.563L20 12l-.031 1.563c-.062 1.166-.166 2.03-.311 2.593a2.18
				2.18 0 0 1-.56.938c-.27.27-.593.458-.966.562-.54.146-1.806.25-3.799.313L11.5 18l-2.833-.031c-1.993-.063-3.26-.167-3.799-.313a2.156 2.156 0 0 1-.965-.562 2.18 2.18 0 0 1-.56-.938c-.146-.562-.25-1.427-.312-2.593L3 12c0-.458.01-.98.031-1.563.062-1.145.166-2 .311-2.562.104-.375.291-.698.56-.969.27-.27.593-.458.966-.562.54-.146 1.806-.25 3.799-.313L11.5 6l2.833.031c1.993.063 3.26.167 3.799.313.373.104.695.291.965.562.27.271.457.594.56.969zm-9.902 6.688L14.21 12 9.756 9.469v5.094z"
      />
    </svg>
  );
};

YoutubeIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
