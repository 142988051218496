import React, { useEffect, useState } from "react";
import PT from "prop-types";

import { NavBarToggleBtn, Search, MenuGroup } from "../../atoms";
import { SideBarMenu } from "../index";
import { Logo } from "../../atoms/icon";
import throttle from "lodash/throttle";
import "./header.m.css";

export const TopHeader = ({ verticalMenuItems, horizontalMenuItems }) => {
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
  // const [isSearchFormOpen, setSearchFormOpen] = useState(false);

  const handleScroll = e => {
    const header = document.getElementById("sticky-header");
    if (header) {
      const sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleScroll, 50));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const onMegaMenuToggle = () => {
    setMegaMenuOpen(!isMegaMenuOpen);
  };

  return (
    <div id="sticky-header">
      <div styleName="wrapper">
        <div styleName="wrapper-inside">
          <NavBarToggleBtn onMegaMenuToggle={onMegaMenuToggle} isMegaMenuOpen={isMegaMenuOpen} />
          <span styleName="logo-wrapper">
            <a href="/">
              <div className="logo">
                <Logo color="#fff" height={36} width={110} />
              </div>
            </a>
          </span>
          <div>
            <Search />
          </div>
        </div>
      </div>

      <SideBarMenu onMegaMenuToggle={onMegaMenuToggle} isMegaMenuOpen={isMegaMenuOpen} menuItems={verticalMenuItems} />
      <MenuGroup menuItems={horizontalMenuItems} />
    </div>
  );
};

TopHeader.propTypes = {
  // menuItem: PT.array,
  verticalMenuItems: PT.array,
  horizontalMenuItems: PT.array
};
