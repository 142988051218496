import React from "react";
import PT from "prop-types";

export const GoogleIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="nonzero"
        d="M14.842 11.086c.074.42.111.816.111 1.187 0 1.361-.284 2.567-.853 3.619a6.173 6.173 0 0 1-2.413 2.467c-1.039.594-2.226.891-3.562.891a6.975 6.975 0 0 1-3.581-.965 7.071 7.071 0 0 1-2.598-2.598A6.98 6.98 0 0 1 1 12.125a6.98 6.98 0 0 1 .946-3.563 7.071 7.071 0 0 1 2.598-2.597A6.975 6.975 0 0 1 8.125 5c1.855 0 3.439.618 4.75 1.855l-1.93 1.856c-.742-.718-1.682-1.076-2.82-1.076-.792 0-1.528.198-2.208.594-.68.395-1.218.94-1.614 1.632a4.484 4.484 0 0 0-.594 2.264c0 .816.198 1.57.594 2.264a4.352 4.352 0 0 0 1.614 1.632c.68.396 1.416.594 2.208.594.866 0 1.62-.185 2.264-.556.52-.297.94-.718 1.261-1.262.272-.42.446-.829.52-1.225H8.125v-2.486h6.717zm5.865.26h2.078v2.078h-2.078v2.078h-2.078v-2.078h-2.041v-2.078h2.04V9.268h2.079v2.078z"
      />
    </svg>
  );
};

GoogleIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
