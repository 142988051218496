import React from "react";
import PT from "prop-types";
import "./headline.m.css";
import Shiitake from "shiitake";

export const Headline = ({ value, fontSize, lines }) => {
  return (
    <Shiitake lines={lines} styleName={`headline-${fontSize}`} className="headline" tagName="h2">
      {value}
    </Shiitake>
  );
};

Headline.defaultProps = {
  lines: 4
};

Headline.propTypes = {
  value: PT.string.isRequired,
  fontSize: PT.oneOf(["large", "base", "halfjumbo", "larger"]).isRequired,
  lines: PT.number
};
