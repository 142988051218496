import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { getHeadline } from "../../helper/utils";

import { SectionName } from "../../atoms/section-name";
import { Headline } from "../../atoms/headline";
import { ByName } from "../../atoms/by-name";
import "./story-card-without-image.m.css";

export const StoryCardWithoutImage = ({ story, fontSize, lines }) => {
  const section = story.sections[0];
  const FontSize = fontSize || "halfjumbo";
  const authors = story.authors;

  return (
    <div styleName="wrapper" className="story-card-without-image">
      <Link href={`/${section.slug}`}>
        <SectionName name={section.name} />
      </Link>
      <Link href={`/${story.slug}`}>
        <Headline value={getHeadline(story)} fontSize={FontSize} lines={lines} />
      </Link>
      <ByName authors={authors} />
    </div>
  );
};

StoryCardWithoutImage.propTypes = {
  story: PT.object,
  fontSize: PT.string,
  lines: PT.number
};
