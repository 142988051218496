import React from "react";
import PT from "prop-types";
import "./multimedia-tile.m.css";
import { Link } from "@quintype/components";
import { ImageCard16x9, Headline, SectionName, PlayIcon } from "../../atoms";
import { getHeadline } from "../../helper/utils";

export const MultimediaTile = ({ story, type, showHeading, lines }) => {
  const section = story.sections[0];

  return (
    <div styleName={`container-${type}`} className="multimedia-content">
      <Link href={`/${story.slug}`}>
        <div styleName="image-wrapper" className="image-wrapper">
          <ImageCard16x9 story={story} />
          <div styleName="play-icon-wrapper">
            <PlayIcon type={type} />
          </div>
        </div>
      </Link>
      {showHeading && (
        <div styleName="content-wrapper" className="multimedia-content-wrapper">
          {type === "large" ? (
            <Link href={`/${section.slug}`}>
              <SectionName name={section.name} />
            </Link>
          ) : null}
          <Link href={`/${story.slug}`}>
            <Headline value={getHeadline(story)} fontSize={type === "large" ? "halfjumbo" : "base"} lines={lines} />
          </Link>
        </div>
      )}
    </div>
  );
};

MultimediaTile.defaultProps = {
  showHeading: true
};

MultimediaTile.propTypes = {
  type: PT.oneOf(["large", "small"]).isRequired,
  story: PT.object,
  showHeading: PT.bool,
  lines: PT.number
};
