import React from "react";
import PT from "prop-types";

export const ShareIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.515 4c-1.366 0-2.486 1.201-2.486 2.667 0 .284.042.563.12.82L9.045 9.936a2.37 2.37 0 0 0-1.56-.603C6.12 9.333 5 10.535 5 12c0 1.465 1.12 2.667 2.485 2.667.59 0 1.132-.224 1.56-.596l4.11 2.448c-.078.258-.126.53-.126.814C13.03 18.8 14.15 20 15.515 20 16.88 20 18 18.799 18 17.333c0-1.465-1.12-2.666-2.485-2.666-.686 0-1.312.307-1.763.795l-3.996-2.385a2.815 2.815 0 0 0 0-2.154l3.99-2.385a2.4 2.4 0 0 0 1.769.795c1.365 0 2.485-1.2 2.485-2.666S16.88 4 15.515 4z"
      />
    </svg>
  );
};

ShareIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
