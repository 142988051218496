import React from "react";

import { SearchBase } from "./search.js";
import "./search.m.css";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFormOpen: false,
      initialized: false
    };
  }

  componentDidMount() {
    this.setState({
      initialized: true
    });
  }

  toggleSearchForm = () => {
    this.setState({ isSearchFormOpen: !this.state.isSearchFormOpen }, () => {
      document.getElementById("formDesk") && document.getElementById("formDesk").focus();
      document.getElementById("formMob") && document.getElementById("formMob").focus();
    });
  };

  render() {
    const formStyle = {
      opacity: this.state.isSearchFormOpen ? 1 : 0
    };
    const initialStyle = {
      opacity: this.state.isSearchFormOpen ? 0 : 1
    };
    const searchStyle = {
      transform: this.state.isSearchFormOpen ? "translate(0, 0)" : ""
    };

    return (
      <React.Fragment>
        <div styleName="qt-search">
          <SearchBase
            toggleSearchForm={this.toggleSearchForm}
            formStyle={formStyle}
            searchStyle={searchStyle}
            initialStyle={initialStyle}
            id="formDesk"
          />
        </div>
        <div styleName="search-wraper" style={searchStyle}>
          <div className="container">
            <div styleName="search-wrapper-inside">
              <SearchBase toggleSearchForm={this.toggleSearchForm} initialStyle={initialStyle} id="formMob" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Search };
