import React from "react";
import PT from "prop-types";
import "./author-name.m.css";

export const AuthorName = ({ name, fontSize }) => {
  return (
    <div styleName={`name-${fontSize}`} className="author-name">
      {name}
    </div>
  );
};

AuthorName.propTypes = {
  name: PT.string,
  fontSize: PT.oneOf(["large", "base"]).isRequired
};
