import React from "react";
import PT from "prop-types";

export const MailIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.688 9.969c.062-.063.13-.073.203-.031.073.041.109.104.109.187V16.5c0 .417-.146.77-.438 1.063A1.447 1.447 0 0 1 18.5 18h-13c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 4 16.5v-6.375c0-.083.036-.14.11-.172a.202.202 0 0 1 .202.016c.688.52 2.292 1.698 4.813 3.531l.313.281c.5.375.895.646 1.187.813.5.27.958.406 1.375.406.417 0 .875-.146 1.375-.438.313-.166.708-.437 1.188-.812l.312-.25c2.458-1.77 4.063-2.948 4.813-3.531zM12 14c-.27 0-.604-.125-1-.375-.23-.125-.552-.354-.969-.688l-.312-.218C7.156 10.844 5.417 9.552 4.5 8.844l-.219-.156A.712.712 0 0 1 4 8.094V7.5c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 5.5 6h13c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v.594c0 .25-.094.448-.281.594l-.157.124c-.895.709-2.656 2.01-5.28 3.907l-.313.219c-.417.333-.74.562-.969.687-.396.25-.73.375-1 .375z"
      />
    </svg>
  );
};

MailIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
