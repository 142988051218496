import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { SocialMediaIcons } from "../index";

import "./side-bar-menu.m.css";

export const SideBarMenu = props => {
  return (
    <div styleName={`wrapper sidebar-menu ${props.isMegaMenuOpen ? "is-open" : ""}`}>
      <ul styleName="menu-list">
        {get(props, ["menuItems"], []).map((item, index) => {
          return (
            <li styleName="menu-item" key={index}>
              <Link href={item.completeUrl} className="menu-item-link">
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
      <div styleName="icons-wrapper">
        <SocialMediaIcons color="#fff" height={24} width={24} />
      </div>
    </div>
  );
};

SideBarMenu.propTypes = {
  isMegaMenuOpen: PT.bool
};
