import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";

import "./by-name.m.css";

export const ByName = ({ authors = [] }) => {
  const getAuthors = authors => {
    return (
      <React.Fragment>
        {authors.map((item, index) => {
          return (
            <Link href={`/author/${item.id}`} key={index}>
              <span styleName="author">{(index ? ", " : "") + item.name.trim()}</span>
            </Link>
          );
        })}
      </React.Fragment>
    );
  };
  return (
    authors.length && (
      <div styleName="wrapper" className="author-name">
        <span styleName="by">By</span> {getAuthors(authors)}
      </div>
    )
  );
};

ByName.propTypes = {
  authors: PT.array
};
