import React from "react";
import PT from "prop-types";
import {
  FaceBookIcon,
  LinkedInIcon,
  TwitterIcon,
  GoogleIcon,
  InstagramIcon,
  YoutubeIcon,
  MailIcon
} from "../../atoms/icon";

import "./styles.m.css";

const iconsData = [
  {
    componentName: TwitterIcon,
    url: "https://twitter.com/FortuneIndia"
  },
  {
    componentName: FaceBookIcon,
    url: "https://www.facebook.com/fortuneind/"
  },
  {
    componentName: GoogleIcon,
    url: "https://plus.google.com/100287022162827136313"
  },
  {
    componentName: MailIcon,
    url: "mailto:digital@fortuneindia.com"
  },
  {
    componentName: LinkedInIcon,
    url: "https://www.linkedin.com/company/fortune-india"
  },
  {
    componentName: InstagramIcon,
    url: "https://www.instagram.com/fortune.india/"
  },
  {
    componentName: YoutubeIcon,
    url: "https://www.youtube.com/channel/UCKomZAH0tqC25GJqe3Spykw"
  }
];

export const SocialMediaIcons = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <ul styleName="icon-list">
      {iconsData.map((item, index) => {
        const SocialMediaIcon = item.componentName;
        return (
          <li styleName="icon-item" key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <SocialMediaIcon color={color} width={width} height={height} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

SocialMediaIcons.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
