import React from "react";
import PT from "prop-types";
import "./toggle-button.m.css";

export const ToggleButton = ({ isOpen, toggleClick }) => {
  return (
    <div styleName={isOpen ? "toggle-btn" : ""} onClick={toggleClick}>
      <div styleName={isOpen ? "plus-symbol" : "hyphen-symbol"} />
    </div>
  );
};

ToggleButton.propTypes = {
  toggleClick: PT.func.isRequired,
  isOpen: PT.bool.isRequired
};
