import React from "react";
import PT from "prop-types";
import "./button.m.css";

export const Button = ({ type, content, ...props }) => {
  return (
    <button styleName={type} className="button" {...props}>
      {content}
    </button>
  );
};

Button.defaultProps = {
  type: "button"
};

Button.propTypes = {
  content: PT.string.isRequired,
  type: PT.oneOf(["button", "outline"]).isRequired
};
