import React from "react";
import PT from "prop-types";
import { ResponsiveImage } from "@quintype/components";
import { getImageAttributes } from "../../helper/utils";

import "./image-card-16x9.m.css";

export const ImageCard16x9 = ({ story }) => {
  return (
    <figure styleName="qt-image-16x9">
      <ResponsiveImage
        {...getImageAttributes(story)}
        aspectRatio={[16, 9]}
        defaultWidth={764}
        widths={[250, 480, 764]}
        sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
        imgParams={{ auto: ["format", "compress"] }}
        styleName="image-wrapper"
        className="image-card-16x9"
      />
    </figure>
  );
};

ImageCard16x9.propTypes = {
  story: PT.object
};
