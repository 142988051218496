import React from "react";
import PT from "prop-types";

export const TwitterIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="c"
          d="M19.137 8.5c.023.09.035.236.035.438 0 1.615-.41 3.174-1.23 4.677a9.767 9.767 0 0 1-3.551 3.803C12.727 18.473 10.816 19 8.66 19 6.597 19 4.71 18.473 3 17.418c.258.023.55.034.879.034 1.71 0 3.246-.505 4.605-1.515-.82 0-1.54-.23-2.162-.69-.62-.46-1.049-1.037-1.283-1.733.234.023.457.034.668.034.328 0 .656-.034.984-.1a3.748 3.748 0 0 1-2.109-1.246c-.562-.65-.844-1.391-.844-2.221v-.068a3.59 3.59 0 0 0 1.653.472 3.984 3.984 0 0 1-1.196-1.28 3.282 3.282 0 0 1-.457-1.699c0-.617.176-1.206.528-1.767a10.101 10.101 0 0 0 3.357 2.625c1.324.651 2.736 1.01 4.236 1.077a4.7 4.7 0 0 1-.07-.807c0-.629.164-1.217.492-1.767.328-.55.774-.982 1.336-1.296A3.688 3.688 0 0 1 15.445 5c.54 0 1.037.101 1.494.303.457.202.862.471 1.213.808a7.722 7.722 0 0 0 2.356-.842c-.281.83-.82 1.481-1.617 1.952A8.204 8.204 0 0 0 21 6.65 7.931 7.931 0 0 1 19.137 8.5z"
        />
      </defs>
      <use fill={color} fillRule="evenodd" xlinkHref="#c" />
    </svg>
  );
};

TwitterIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
