import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { getHeadline } from "../../helper/utils";
import { ImageCard16x9, SectionName, Headline, ByName } from "../../atoms";
import "./story-card-with-image.m.css";

export const StoryCardWithImage = ({ story, lines }) => {
  const section = story.sections[0];

  return (
    <div styleName="wrapper">
      <Link href={`/${story.slug}`}>
        <ImageCard16x9 story={story} />
      </Link>
      <Link href={`/${section.slug}`}>
        <SectionName name={section.name} />
      </Link>
      <Link href={`/${story.slug}`}>
        <Headline value={getHeadline(story)} fontSize="large" lines={lines} />
      </Link>
      <ByName authors={story.authors} />
    </div>
  );
};

StoryCardWithImage.propTypes = {
  story: PT.object,
  lines: PT.number
};
