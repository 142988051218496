import React from "react";
import PT from "prop-types";

export function Logo({ color = "#d0021b", width = 16, height = 16 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g fill={color} fillRule="evenodd">
        <path
          d="M93 1h-3.98v13.654L84.98 1H81v25h3.98V13.756L88.597 26H93zM4.1
        1H0v25h4.1V15.294h3.788v-4.23H4.1V5.231H10V1zM21.873 19.895c0 .884-.7 1.642-1.653 1.642a1.637
        1.637 0 0 1-1.654-1.642V6.126c0-.884.7-1.642 1.654-1.642.89 0 1.653.695
        1.653 1.642v13.769zM20.156.315c-3.18.064-5.85 2.653-5.85 5.811v13.769c0 3.221
        2.607 5.81 5.85 5.81 3.243 0 5.85-2.589 5.85-5.81V6.126c0-3.22-2.607-5.81-5.85-5.81zM47.752
        4.863h3.751v20.463H55.7V4.863h3.687V.695H47.752zM71.723 19.895c0 .884-.7 1.642-1.654
        1.642a1.636 1.636 0 0 1-1.653-1.642V.695H64.22v19.2c0 3.221 2.608 5.81 5.85 5.81 3.243
        0 5.85-2.589 5.85-5.81V.695h-4.196v19.2zM109.682 4.863V.695H99.445v24.631h10.237v-4.168h-6.04v-6.379h3.879v-4.168h-3.88V4.863zM43.11 10.295V6.253A5.794 5.794 0 0 0 37.324.695h-5.786v24.631h4.196V4.863h1.59c.953 0 1.653.758 1.653 1.642v3.853c0
        2.779-2.67 3.284-2.67 3.284l3.115 11.684h4.324L40.82 15.6c1.462-1.137 2.289-2.842 2.289-5.305M107.075 33.663l.445-2.968.064-1.137h.063l.064 1.137.381 2.968h-1.017zm-.127-5.874L105.486 36h1.208l.19-1.262h1.4l.19 1.263h1.208l-1.462-8.21h-1.272zM103.133 36h1.208v-8.21h-1.208zM100.526 34.674c0 .126-.19.252-.318.252H99.19V28.8h1.018c.191 0
        .318.19.318.316v5.558zm.763-6.505c-.191-.253-.51-.38-.827-.38h-2.48V36h2.48c.318 0
        .636-.127.827-.316.254-.252.382-.568.382-.884v-5.874a.897.897 0 0 0-.382-.757zM95.375 30.316l.192 3.347h-.064l-.318-1.2-1.526-4.674h-1.145V36h1.145v-2.525l-.191-3.348h.064l.318 1.2L95.375 36h1.146v-8.21h-1.146zM89.844 36h1.207v-8.21h-1.207z"
        />
      </g>
    </svg>
  );
}

Logo.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
