import React from "react";
import PT from "prop-types";
import Glide from "@glidejs/glide";
import "./styles.m.css";

export class Slider extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    const { options, carouselName } = this.props;
    if (this.props.children) {
      this.glide = new Glide(`.${carouselName}`, options).mount();
      this.glide.on(["mount.after", "move.after"], () => {
        this.setState({ activeIndex: this.glide.index });
      });
    }
  }

  componentWillUnmount() {
    if (this.props.children && this.glide) {
      this.glide.destroy();
    }
  }

  renderChildren() {
    return this.props.children.map((child, index) => {
      return (
        <li key={index} className="glide__slide">
          {child}
        </li>
      );
    });
  }

  renderBottomControls() {
    const { activeIndex } = this.state;
    return (
      <div styleName="controls-wrapper">
        {this.props.children.map((child, index) => (
          <div
            key={index}
            styleName={activeIndex === index ? "item-indicator-active" : "item-indicator"}
            onClick={() => this.glide.go("=" + index)}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={this.props.carouselName}>
        <div data-glide-el="track" className="glide__track">
          <ul className="glide__slides">{this.renderChildren()}</ul>
        </div>
        {this.renderBottomControls()}
      </div>
    );
  }
}

Slider.propTypes = {
  options: PT.object,
  carouselName: PT.string,
  children: PT.any
};
