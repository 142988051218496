import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { ImageCard16x9, ByName, Headline } from "../../atoms";
import { getHeadline } from "../../helper/utils";

import "./image-headline-byname.m.css";

export const ImageHeadlineByName = ({ story, lines }) => {
  return (
    <div styleName="wrapper">
      <Link href={`/${story.slug}`}>
        <ImageCard16x9 story={story} />
      </Link>
      <Link href={`/${story.slug}`}>
        <Headline value={getHeadline(story)} fontSize="large" lines={lines} />
      </Link>
      <ByName authors={story.authors} />
    </div>
  );
};

ImageHeadlineByName.propTypes = {
  story: PT.object.isRequired,
  lines: PT.number
};
