import React from "react";
import get from "lodash/get";
import PT from "prop-types";
import { connect } from "react-redux";

import { TopHeader } from "../molecules/header";
import "./styles.m.css";

class HeaderBase extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <TopHeader horizontalMenuItems={this.props.menu.horizontal} verticalMenuItems={this.props.menu.vertical} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], {})
  };
}
HeaderBase.propTypes = {
  menu: PT.object
};

export const Header = connect(
  mapStateToProps,
  () => ({})
)(HeaderBase);
