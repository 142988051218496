import React from "react";
import PT from "prop-types";

import "./section-name.m.css";

export const SectionName = ({ name }) => {
  return (
    <h3 styleName="section-name" className="section-name">
      {name}
    </h3>
  );
};

SectionName.propTypes = {
  name: PT.string
};
