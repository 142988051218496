import React from "react";
import PT from "prop-types";
import "./author-image.m.css";

export const AuthorImage = ({ authorObj }) => {
  const { name, "avatar-url": avatarUrl } = authorObj;
  return (
    <div styleName="wrapper" className="author-image">
      {avatarUrl && <img styleName="picture" src={avatarUrl} alt={name} />}
    </div>
  );
};

AuthorImage.propTypes = {
  authorObj: PT.object
};
