import React from "react";
import get from "lodash/get";
import { Link } from "@quintype/components";

import "./menu-group.m.css";

export const MenuGroup = props => {
  const menuItems = get(props, ["menuItems"], []);
  return (
    <nav styleName="navbar">
      <ul styleName="menu-list">
        {menuItems.map((item, index) => {
          return (
            <li styleName="menu-list-item" key={index}>
              <Link href={item.completeUrl} className="menu-item-link">
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
