import React from "react";
import PT from "prop-types";
import { ResponsiveImage } from "@quintype/components";
import { getImageAttributes } from "../../helper/utils";

import "./image-card-4x3.m.css";

export const ImageCard4x3 = ({ story }) => {
  return (
    <figure styleName="qt-image-4x3" className="image-card-4x3">
      <ResponsiveImage
        {...getImageAttributes(story)}
        aspectRatio={[4, 3]}
        defaultWidth={480}
        widths={[336, 655]}
        sizes="( max-width: 500px ) 98vw, ( max-width: 768px ) 48vw, 23vw"
        imgParams={{ auto: ["format", "compress"] }}
        styleName="image-wrapper"
      />
    </figure>
  );
};

ImageCard4x3.propTypes = {
  story: PT.object
};
