import React from "react";
import PT from "prop-types";

import { getHeadline } from "../../helper/utils";
import { ImageCard4x3 } from "../../atoms/image-card-4x3";
import { SectionName } from "../../atoms/section-name";
import { Headline } from "../../atoms/headline";
import { SubHeadline } from "../../atoms/subheadline";
import { ByName } from "../../atoms/by-name";
import { Link } from "@quintype/components";
import "./horizontal-card.m.css";

export const HorizontalStoryCard = ({ story, lines, isSubHeadline }) => {
  const section = story.sections[0];

  return (
    <div styleName="wrapper">
      <div styleName="story-image" className="story-image">
        <Link href={`/${story.slug}`}>
          <ImageCard4x3 story={story} />
        </Link>
      </div>
      <div styleName="story-content">
        <Link href={`/${section.slug}`}>
          <SectionName name={section.name} />
        </Link>
        <Link href={`/${story.slug}`}>
          <Headline value={getHeadline(story)} fontSize="larger" lines={lines} />
        </Link>
        {isSubHeadline && <SubHeadline value={story.subheadline} lines={lines} />}
        <ByName authors={story.authors} />
      </div>
    </div>
  );
};

HorizontalStoryCard.defaultProps = {
  isSubHeadline: true
};

HorizontalStoryCard.propTypes = {
  story: PT.object,
  isSubHeadline: PT.bool,
  lines: PT.number
};
