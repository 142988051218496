import React from "react";
import PT from "prop-types";

import { AuthorImage } from "../../atoms/author-image";
import { ByName } from "../../atoms/by-name";
import "./author-with-image-name.m.css";

export const AuthorWithImageName = ({ authors }) => {
  return (
    <div styleName="wrapper">
      {authors.length === 1 &&
        authors[0]["avatar-url"] && (
          <div styleName="author-img">
            <AuthorImage authorObj={authors[0]} />
          </div>
        )}
      <div>
        <ByName authors={authors} />
      </div>
    </div>
  );
};

AuthorWithImageName.propTypes = {
  authors: PT.array.isRequired
};
