import { SearchIcon } from "./search";
import { Logo } from "./logo";
import { CloseIcon } from "./close";
import { FaceBookIcon } from "./fb";
import { TwitterIcon } from "./twitter";
import { GoogleIcon } from "./google";
import { InstagramIcon } from "./instagram";
import { YoutubeIcon } from "./youtube";
import { MailIcon } from "./mail";
import { ShareIcon } from "./share";
import { LinkedInIcon } from "./linkedin";
import { QuintypeIcon } from "./quintype-logo";
import { GoTopIcon } from "./gotopicon";

export {
  SearchIcon,
  Logo,
  CloseIcon,
  FaceBookIcon,
  TwitterIcon,
  GoogleIcon,
  InstagramIcon,
  YoutubeIcon,
  MailIcon,
  ShareIcon,
  LinkedInIcon,
  QuintypeIcon,
  GoTopIcon
};
