import React from "react";
import PT from "prop-types";
import "./play-icon.m.css";

export const PlayIcon = ({ type }) => {
  return (
    <div styleName={`container-${type}`}>
      <div styleName={`play-icon-${type}`} />
    </div>
  );
};

PlayIcon.propTypes = {
  type: PT.oneOf(["large", "small"]).isRequired
};
