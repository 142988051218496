import React from "react";
import PT from "prop-types";

export const LinkedInIcon = ({ color = "#000", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>4C042F24-DEFE-4358-A20C-44EB74298DF4</title>
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M7.84821429,19.5 L4.734375,19.5 L4.734375,9.48883929 L7.84821429,9.48883929 L7.84821429,19.5 Z M6.30803571,8.11607143 C5.81696412,8.11607143 5.39285731,7.93750017 5.03571429,7.58035714 C4.67857126,7.22321412 4.5,6.79910731 4.5,6.30803571 C4.5,5.81696412 4.67857126,5.39285731 5.03571429,5.03571429 C5.39285731,4.67857126 5.81696412,4.5 6.30803571,4.5 C6.79910731,4.5 7.22321412,4.67857126 7.58035714,5.03571429 C7.93750017,5.39285731 8.11607143,5.81696412 8.11607143,6.30803571 C8.11607143,6.79910731 7.93750017,7.22321412 7.58035714,7.58035714 C7.22321412,7.93750017 6.79910731,8.11607143 6.30803571,8.11607143 Z M19.5,19.5 L16.3861607,19.5 L16.3861607,14.6116071 C16.3861607,13.8080357 16.3191964,13.2276787 16.1852679,12.8705357 C15.9397323,12.2678571 15.4598213,11.9665179 14.7455357,11.9665179 C14.0312502,11.9665179 13.529018,12.234375 13.2388393,12.7700893 C13.0156248,13.171875 12.9040179,13.763393 12.9040179,14.5446429 L12.9040179,19.5 L9.82366071,19.5 L9.82366071,9.48883929 L12.8035714,9.48883929 L12.8035714,10.8616071 L12.8370536,10.8616071 C13.060268,10.4151787 13.4174105,10.0468752 13.9084821,9.75669643 C14.4441964,9.3995534 15.0691966,9.22098214 15.7834821,9.22098214 C17.2343748,9.22098214 18.2500002,9.6785716 18.8303571,10.59375 C19.2767855,11.3303571 19.5,12.46875 19.5,14.0089286 L19.5,19.5 Z"
          id="path-1"
        />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/LinkedIn/Regular">
          <mask id="mask-2" fill={color}>
            <use xlinkHref="#path-1" />
          </mask>
          <use id="linkedin-in" fill={color} xlinkHref="#path-1" />
        </g>
      </g>
    </svg>
  );
};

LinkedInIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};
