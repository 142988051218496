import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { getHeadline } from "../../helper/utils";

import { Headline, ByName } from "../../atoms";
import { ImageCard4x3 } from "../../atoms/image-card-4x3";
import "./horizontal-card-with-image-4x3.m.css";

export const HorizontalCardWithImage4x3 = ({ story, fontSize, lines }) => {
  const FontSize = fontSize || "base";

  return (
    <div styleName="wrapper" className="card-list">
      <Link href={`/${story.slug}`}>
        <ImageCard4x3 story={story} />
      </Link>
      <div styleName="content">
        <Link href={`/${story.slug}`}>
          <Headline value={getHeadline(story)} fontSize={FontSize} lines={lines} />
        </Link>
        <ByName authors={story.authors} />
      </div>
    </div>
  );
};

HorizontalCardWithImage4x3.propTypes = {
  story: PT.object,
  fontSize: PT.string,
  lines: PT.number
};
